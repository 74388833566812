<!-- 选择充值单 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="font-size18 font-color-333 font-weight700 margin-t-15">
				钱包提现
			</div>
			<div class="margin-t-6 recharge-wallet-box border-F2F2F2">
				<div class="font-size16 font-weight700 font-color-333">充值单</div>
				<div class="flex-row-wrap">
					<div v-for="(item, index) in rechargeList" :key="index" @click="selsctRecharge(item)" class="flex-row-space-between-center choose-recharge-list-top-box padding-lr-10 margin-r-12 margin-t-17">
						<div class="flex-colum font-size14">
							<div class="font-weight700">
								充值 ¥{{item.amount}}
							</div>
							<div class="">
								日期：2020-04-27
							</div>
						</div>
						<div class="color-theme">
							可退金额 ¥{{item.returnable}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				rechargeList: [{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					},
					{
						amount: "5,000.00",
						returnable: "200.56"
					}
				]
			};
		},
		mounted() {},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			selsctRecharge(e) {
				this.$router.push({
					path: '/businessme/WithdrawalWallet',
					query: {
						isSelect: true
					}
				});
			}
		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.recharge-wallet-box {
		padding: 22px 0 0 17px;
		width: 900px;
		min-height: 466px;
	}

	.choose-recharge-list-top-box {
		width: 263px;
		height: 72px;
		border-radius: 5px;
		background-image: linear-gradient(to right, #F0F3FF, #DCE3FE);
	}

	.choose-recharge-list-top-box:hover {
		cursor: pointer;
	}
</style>
